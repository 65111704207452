/**
 * IMPORTS
 */
import { createApp, configureCompat } from 'vue';
import App from './App.vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import './assets/scss/custom.scss';

/**
 * CONFIGURATION
 */
configureCompat({ MODE: 2 });
const APP = createApp(App);
APP.use(BootstrapVue);
APP.use(BootstrapVueIcons);

/**
 * APPLICATION
 */
APP.mount('#app');
